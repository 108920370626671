/*!
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

@use "media";
@use "primary";
@use "secondary";
@use "tp";
@use "shapes";
@use "typography";

$box-width: 485px;

tp-login-page {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
    height: 100%;
}

tp-login-page, .setup-container {
    .tp-split-panes {
        width: 100%;
        height: 100%;
        display: flex;

        > * {
            flex: 1;
        }

        div.tp-primary-pane {
            background: primary.$purple;
            border-right: shapes.$border;
            display: flex;
            justify-content: center;
            align-items: center;

            @media (min-width: media.$min-width-tablet) {
                padding: 24px;
            }

            .tp-content-area {
                @media (min-width: 1200px) {
                    width: 500px;
                }
            }
        }

        div.tp-secondary-pane {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 24px;
            background: no-repeat 0 50vh / 100% 66% url("/assets/image/cloud-crop.webp") ;

            @media (max-width: media.$max-width-mobile) {
                display: none;
            }

            .tp-content-area {
                @media (min-width: 1200px) {
                    width: 500px;
                }
            }
        }
    }

    h1 {
        margin-top: 64px;
        font-size: 31px;
        line-height: 39px;
    }

    .form-container {
        padding: 32px;
        box-sizing: border-box;
        width: $box-width;
        height: fit-content;
        background-color: primary.$deep-purple;
        color: primary.$white;
        @include shapes.standard-border;
        text-align: center;
    }

    tp-button {
        display: flex;
        height: fit-content;
    }

    .check-email-notification {
        margin-top: 32px;
        display: flex;
        flex-direction: column;
    }

    .check-email-notification {
        @include shapes.standard-border;
        border-color: primary.$green;
        box-sizing: border-box;
        align-items: center;
        justify-content: center;
        padding-top: 14px;
        padding-bottom: 14px;
        height: min-content;
        background-color: tp.$green;
        color: primary.$white;
    }

    .check-email-icon {
        color: primary.$green;
    }

    .check-email-text {
        display: flex;
        gap: 12px;
        align-items: center;
        @include typography.h4;
    }

    .box-text {
        margin: 0 0 24px 0;
        color: primary.$grey;
        @include typography.p3;
    }

    .box-text-clickable {
        color: primary.$white;
        @include typography.subtitle2;
    }

    .box-title {
        margin-top: 0;
        margin-bottom: 28px;
        @include typography.modal-title;
    }

    .box-subtitle {
        margin-top: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;
        @include typography.h4;
    }

    .box-content {
        margin-top: 32px;
    }

    .tp-login, .tp-promotional-area {
        font-size: 16px;
        font-weight: typography.$light;
    }

    .tp-login-form-header {
        justify-content: space-between;
        align-items: center;

        p {
            margin-top: 16px;
            color: primary.$grey;
            font-size: 20px;
        }
    }

    .tp-sso-buttons {
        margin-top: 80px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;

        button {
            width: 100%;
            flex: 1;
            font-weight: typography.$light;
            font-size: 16px;
            line-height: 24px;
            gap: 16px;
            padding: 12px 16px;
            @include shapes.standard-border;
            background: secondary.$purple;
            color: primary.$white;
            display: flex;
            align-items: center;
            justify-content: center;

            img, svg {
                width: 28px;
                height: 28px;
            }

            &:hover, &:focus {
                border-color: primary.$green;
            }
        }

        @media (max-width: media.$max-width-mobile) {
            flex-direction: column;
            margin-top: 16px;
        }
    }

    .tp-login-method-separator {
        margin-top: 32px;
        display: flex;
        gap: 24px;
        align-items: center;
        text-transform: uppercase;
        color: secondary.$grey;
        letter-spacing: 0.05em;

        hr {
            flex: 1;
            height: 1px;
            border: 0;
            margin: 0;
            background-color: primary.$light-purple;
        }
    }

    .tp-login-form-body {
        display: block;
        margin-top: 32px;

        p {
            text-align: end;
        }

        .terms-privacy-additional-info {
            margin-top: 12px;
        }
    }

    .tp-login-form-password-row {
        display: flex;
        gap: 16px;

        tp-form-password-input {
            flex: 1;
            min-width: 0;
        }

        tp-form-actions {
            margin: 0;
            flex: 0 0 120px;
        }
    }

    .tp-login-form-actions {
        align-self: flex-end;
        width: 180px;
    }

    .tp-forgot-password-link {
        display: block;
        margin-top: 12px;
    }

    .tp-content-area {
        text-align: start;

        img.tp-logo-large {
            display: block;
            width: 340px;
        }

        img.tp-logo {
            display: block;
            width: 180px;
        }

        .tp-headline {
            font-size: 25px;
            line-height: 33px;
        }

        ul, li {
            list-style-type: none;
            font-size: 20px;
            line-height: 30px;
            font-weight: typography.$extra-light;
        }

        ul {
            margin-top: 24px;
        }

        li {
            margin-top: 8px;

            i {
                color: primary.$green;
            }

            > div {
                display: flex;
                gap: 12px;
                align-items: center;
            }
        }

        .tp-promotional-placeholder {
            height: 100px;
        }
    }

    .tp-promotional-area {
        .tp-promotional-title h2 {
            font-size: 31px;
            line-height: 39px;
        }

        .tp-headline p {
            margin-top: 48px;
        }
    }

    .tp-email-verification {
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        h1 {
            margin-top: 64px;
            margin-bottom: 24px;
        }

        tp-spinner {
            margin-top: 40px;
        }

        hr {
            width: 100%;
            margin-top: 24px;
            margin-bottom: 24px;
        }

        p + p {
            margin-top: 1em;
        }

        button {
            text-decoration: underline;
        }
    }

    .tp-big-envelope i {
        font-size: 96px;
        width: 96px;
        text-align: center;
    }
}

.tp-finish {
    margin-top: 28px;
    display: flex;
    justify-content: center;
    width: 100%;

    tp-form-actions, tp-button {
        width: 128px;
    }
}

tp-p-with-highlights.tp-setup {
    max-width: 550px;
}

.setup-container {
    min-width: 420px;
}
